<template>
    <v-stepper v-model="Stepper">
        <v-stepper-header>
            <v-divider></v-divider>
            <v-stepper-step
                :complete="Stepper > 1"
                editable
                step="1">
                    Dimensiones
            </v-stepper-step>
            <v-divider></v-divider>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-dialog
                    v-model="dialogDimension"
                    persistent
                    max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                       
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">Nueva dimensión</span>
                        </v-card-title>
                        <v-card-text>
                           <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Nombre dimensión"
                                            v-model="infoDimension.name"
                                            
                                            required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="infoDimension.description"
                                            
                                            label="Descripción dimensión"
                                            required>   
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <img :src="infoDimension.img" style="width: 100%; height: 300px">
                                        <v-file-input
                                            accept="image/*"
                                            v-model="infoDimension.img"
                                            @change="onDimensionImage"
                                            prepend-icon="mdi-camera"
                                            label="Imagen fondo dimensión">
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-color-picker
                                            v-model="infoDimension.color"
                                            mode="hexa"
                                        ></v-color-picker>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="red darken-1"
                                text
                                @click="dialogDimension = false">
                                    Cerrar
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="saveDimension(infoDimension.id)">
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
           
                    
                   <v-row class="mb-5">
                    <v-col lg="3" xl="3" md="4" sm="4" v-for="item in dimensions">
                        <v-card>
                            <v-container class="dimension_container" :style="{'background-image': `url(${item.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}">
                                <div class="dimension_container__title" :style="{'background-color':item.color_dimension}">
                                    <p>
                                        {{item.name_dimension}}
                                    </p>
                                </div>
                            </v-container>
                            <!-- <v-container :style="{'background-color':item.color,height:'200px',border:'1px solid #363636'}">
                                    <v-card-title :style="{'background-color':'#363636',color:'#fff'}"
                                 >{{item.name}}</v-card-title>
                                    <v-card-text :style="{'background-color':'#363636',color:'#fff'}">{{item.description}}</v-card-text>
                            </v-container> -->

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @click="getDimensionById(item.id)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar dimensión</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @click="getSegments(1)">
                                            <v-icon>mdi-arrange-send-to-back</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Segmentos</span>
                                </v-tooltip>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
               
            
            
            <v-dialog
                v-model="dialogSegment"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                scrollable>
      
                <v-card tile>
                    <v-toolbar
                        flat
                        dark
                        color="primary">
                        <v-btn
                            icon
                            dark
                            @click="dialogSegment = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Segmentos</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                                dark
                                text
                                @click="dialogSegment = false">
                                <v-icon left>
                                    mdi-card-plus
                                </v-icon>
                                Nuevo segmento
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <!-- INFORMACIÓN DE CARDS -->
                        <v-row>
                            <v-col cols="6">
                                <v-card class="mt-3">
                                    <v-toolbar
                                        flat
                                        color="primary"
                                        dark>
                                        <v-toolbar-title>Planos y mapas</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-toolbar-items>
                                            <v-btn icon>
                                            <v-icon>mdi-content-save</v-icon>
                                        </v-btn>
                                        <v-btn icon>
                                            <v-icon>mdi-content-save-edit</v-icon>
                                        </v-btn>
                                        </v-toolbar-items>
                                    </v-toolbar>
                                    <v-tabs>
                                        <v-tab>
                                            <v-icon left>
                                            mdi-card-text
                                            </v-icon>
                                            Información
                                        </v-tab>
                                        <v-tab>
                                            <v-icon left>
                                            mdi-ballot
                                            </v-icon>
                                            Lista
                                        </v-tab>
                                        <v-tab>
                                            <v-icon left>
                                            mdi-chart-arc
                                            </v-icon>
                                            Gráficos
                                        </v-tab>
                                            <v-tab-item>
                                                <v-card flat>
                                                    <v-card-text>
                                                        
                                                        <v-form>
                                                            <v-container>
                                                            <v-row>
                                                                <v-col
                                                                    cols="6">
                                                                    <v-text-field
                                                                        label="Nombre segmento"
                                                                        required></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="6">
                                                                    <v-file-input
                                                                        accept="image/*"
                                                                        label="Ícono"
                                                                        prepend-icon="mdi-camera"
                                                                    ></v-file-input>
                                                                </v-col>
                                                            </v-row>
                                                            </v-container>
                                                        </v-form>
                                                    </v-card-text>
                                                </v-card>
                                            </v-tab-item>
                                            <v-tab-item>
                                                <v-card flat>
                                                    <v-card-text>
                                                        <v-form>
                                                            <v-container>
                                                            <v-row>
                                                                <v-col cols="12">
                                                                <v-text-field
                                                                    append-outer-icon="mdi-send"
                                                                    filled
                                                                    clear-icon="mdi-close-circle"
                                                                    clearable
                                                                    label="Item"
                                                                    type="text"
                                                                ></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            </v-container>
                                                        </v-form>
                                                        <v-chip-group
                                                            active-class="primary--text"
                                                            column
                                                        >
                                                            <v-chip close close-icon="mdi-delete">
                                                                Item
                                                            </v-chip>
                                                        </v-chip-group>
                                                    </v-card-text>
                                                </v-card>
                                            </v-tab-item>
                                            <v-tab-item>
                                                <v-card flat>
                                                    <v-card-text>
                                                        Gráficos
                                                    </v-card-text>
                                                </v-card>
                                            </v-tab-item>
                                    </v-tabs>
                                </v-card>
                            </v-col>
                        </v-row>
                        
                        <!-- INFORMACIÓN DE CARDS -->
                    </v-card-text>
                </v-card>
            </v-dialog>
            </v-stepper-content>

        </v-stepper-items>
    </v-stepper>
</template>
<script>
 export default {
    data () {
      return {
        Stepper: 1,
        dialogDimension: false,
        dialogSegment: false,
        titleDimension: 'Nueva dimensión',
        id : this.$route.params.id,
        townhall:[],
        tables:[],
        dimensions:[],
        chartData:{},
        barsData:{},
        manyTables:[],
         infoDimension: {
        },

      }
    },
    methods: {
        onDimensionImage(e){
            console.log('entre a cambio de imagen')
            var files = e
            console.log(e)
            this.current_principal_url = URL.createObjectURL(files)
            console.log(this.current_principal_url)
            console.log(this.createPrincipalImage(files))
            return this.createPrincipalImage(files)
        },
         createPrincipalImage (file) {
        var reader = new FileReader()
        var vm = this
        reader.readAsDataURL(file);
        reader.onload = function () {
          this.infoDimension.img = reader.result
        }
        console.log('al cambio',this.infoDimension.img)
      },
        async getDimensionById(id){
            console.log('entroo')
            this.dialogDimension = true
             try{
                const dimension = await this.$http.get('dimensions/'+id)
                //const dimension = await this.$http.get('dimensions/'+id,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                this.infoDimension = {
                    id:dimension.data.id,
                    name: dimension.data.name_dimension,
                    description: dimension.data.text_dimension,
                    img: dimension.data.img_dimension.url,
                    color: dimension.data.color_dimension
                }
                //console.log('arreglo dimension',this.dimensions)
            }catch(error){
                //console.log(error)
            }

        },
        async saveDimension(id){
            console.log('entroo',this.infoDimension)
            
             try{
                const dimension = await this.$http.put('dimensions/'+id,{
                    data:{
                        attributes:{
                            name_dimension: this.infoDimension.name,
                            text_dimension: this.infoDimension.description,
                            img_dimension: this.infoDimension.img,
                            color_dimension: this.infoDimension.color 
                        }
                    }
                },{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                this.dialogDimension = false
                this.$router.push({ name: 'Dimension' });
                //console.log('arreglo dimension',this.dimensions)
            }catch(error){
                //console.log(error)
            }
        },
        getSegments(Dimension){
    
            this.dialogSegment = true;
        },
        async getTownhall(){
            //console.log('ingrese al twonhall')
             try{
                const townhalls = await this.$http.get('townhalls/'+this.id)
                //const townhalls = await this.$http.get('townhalls/'+this.id,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                this.townhall = townhalls.data
                //console.log('arreglo townhall',this.townhall)
            }catch(error){
                //console.log(error)
            }
            
        },
        async getDimension(){
            //console.log('ingrese al twonhall')
             try{
                const dimension = await this.$http.get('dimensions/townhall/'+this.id)
                //const dimension = await this.$http.get('dimensions/townhall/'+this.id,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                this.dimensions = dimension.data
                //console.log('arreglo dimension',this.dimensions)
            }catch(error){
                //console.log(error)
            }
            
        },
        async getTables(){
            //console.log('ingrese al twonhall')
             try{
                //const tablas = await this.$http.get('tables/townhall/'+this.id,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                const tablas = await this.$http.get('tables/townhall/'+this.id)
                this.tables = tablas.data
                //console.log('arreglo tablas',this.tables)
                this.convertJson()
            }catch(error){
                //console.log(error)
            }
            
        },
        convertJson(){
        for(var j = 0 ; j< this.tables.length ; j++){
                //console.log('entre aca',this.tables.data)
        const beforeParse = this.tables[j].data
        const x = JSON.parse(beforeParse)
        const info = []
        const twoInfo = []
        for( var i = 0 ; i < (x.length-1) ; i++){
          info.push(x[i+1])
        }
        const y = x
        for( var i = 0 ; i < (x.length-1) ; i++){
          var r = [y[i+1][0],y[i+1][2]]
          twoInfo.push(r)
        }

        //console.log('esto es info ',info)
        //console.log('esto es 2 info ',twoInfo)
        this.chartData=info
        this.barsData=[{name:x[0][1],data:info},{name:x[0][2],data:twoInfo}]
        const newJson = JSON.stringify(x)
        //console.log(newJson.toString)


        const obj = {
            name:this.tables[j].name_table,
            type:this.tables[j].graph_type_id,
            chartData:this.chartData,
            barsData:this.barsData
        }
        this.manyTables.push(obj)
        }
        
        //console.log('Las tablas ',this.manyTables)
    },
    },
    beforeMount(){
        this.getTownhall()
        this.getDimension()
        this.getTables()
        

    },
    computed:{
        url(){
            if (!this.infoDimension.img) return;
            return URL.createObjectURL(this.infoDimension.img);
        }
    }
  }
</script>